@import '../../../@core/scss/base/bootstrap-extended.scss';

.details {
  .details-item {
    h4 {
      margin: 16px 0 0px;
    }
    p {
      display: inline-block;
      padding: 8px 16px 0px 0;
      margin: 0;
    }

    &.clickable p:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.react-dataTable.clickable {
  .rdt_TableBody {
    .rdt_TableRow {
      &:hover {
        cursor: pointer;
        background-color: $light;
      }
    }
  }
}

.days-off {
  h4 span:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.dropdown-hover:hover {
  background-color: $gray-600;
  border-radius: 50%;
}
